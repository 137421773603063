import loadable from '@loadable/component'

const routes = [
  {
    path: '/404.html',
    component: loadable(
      () => import(/* webpackChunkName: "ErrorPage" */ './pages/ErrorPage'),
    ),
  },
  {
    path: '/',
    component: loadable(
      () => import(/* webpackChunkName: "Home" */ './pages/Home/Beta'),
    ),
    exact: true,
    translated: true,
  },
  {
    path: '/signup',
    component: loadable(
      () => import(/* webpackChunkName: "SignUpPage" */ './pages/SignUpPage'),
    ),
    translated: true,
  },
  {
    path: '/contact',
    component: loadable(
      () =>
        import(/* webpackChunkName: "GetInContact" */ './pages/GetInContact'),
    ),
  },
  {
    path: '/californiaprivacyrights',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "CaliforniaPrivacyRightsPage" */ './pages/CaliforniaPrivacyRightsPage'
        ),
    ),
  },
  {
    path: '/policy',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PrivacyPolicy" */ './pages/policy/PrivacyPolicy'
        ),
    ),
  },
  {
    path: '/policy-us',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PrivacyPolicyUS" */ './pages/policy/PrivacyPolicyUS'
        ),
    ),
  },
  {
    path: '/policy-de',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PrivacyPolicyUS" */ './pages/policy/PrivacyPolicyDE'
        ),
    ),
  },
  {
    path: '/policy-contact',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PrivacyPolicyDato" */ './pages/policy/PrivacyPolicyDato'
        ),
    ),
  },
  {
    path: '/policy-hiring',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PrivacyPolicyDato" */ './pages/policy/PrivacyPolicyDato'
        ),
    ),
  },
  {
    path: '/three-week-weight-loss',
    // eslint-disable-next-line
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "MealPlansPage" */ './pages/MealPlansPage/MealPlansPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/sugar-detox',
    // eslint-disable-next-line
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "MealPlansPage" */ './pages/MealPlansPage/MealPlansPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/paleo',
    // eslint-disable-next-line
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "MealPlansPage" */ './pages/MealPlansPage/MealPlansPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/how-it-works',
    // eslint-disable-next-line
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "LandingPages-General" */ './pages/LandingPages/GeneralPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/how-it-works/section-preview',
    // eslint-disable-next-line
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "LandingPages-SectionPreview" */ './pages/LandingPages/SectionPreview'
        ),
    ),
    translated: true,
  },
  {
    path: '/how-it-works/:slug',
    // eslint-disable-next-line
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "LandingPages-General" */ './pages/LandingPages/GeneralPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/premium',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PremiumPage" */ './pages/PremiumPage/PremiumPage'
        ),
    ),
    translated: true,
  },
  {
    path: `/account/*`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "UserAccount" */ './pages/UserAccount/UserAccount'
        ),
    ),
    translated: true,
  },
  {
    path: `/deleted`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "Deleted" */ './pages/UserAccount/screens/Deleted'
        ),
    ),
    translated: true,
  },
  {
    path: `/reset/:token`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "ResetPage" */ './pages/ResetPage/ResetPage'
        ),
    ),
    translated: true,
  },
  {
    path: `/redeem/ls5019wb`,
    component: loadable(
      () => import(/* webpackChunkName: "Renew" */ './pages/Renew/Renew'),
    ),
    translated: true,
  },
  {
    path: `/redeem`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "RedeemFormPage" */ './pages/Redeem/RedeemFormPage'
        ),
    ),
    translated: true,
  },
  {
    path: `/fuelwithlove-:country`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "FuelWithLove" */ './pages/FuelWithLovePage/FuelWithLovePage'
        ),
    ),
    translated: true,
  },
  {
    path: `/finalize-payment`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "FinalizePaymentPage" */ './pages/FinalizePaymentPage/FinalizePaymentPage'
        ),
    ),
    translated: true,
  },
  {
    path: [`/welcome`, `/upgrade/welcome`, `/renew/welcome`, `/free/welcome`],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "WelcomePage" */ './pages/WelcomePage/WelcomePage'
        ),
    ),
    translated: true,
  },
  {
    path: `/upgrade`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "UpgradePage" */ './pages/UpgradePage/UpgradePage'
        ),
    ),
    translated: true,
  },
  {
    path: `/upgrade/:code`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "UpgradePage" */ './pages/UpgradePage/UpgradePage'
        ),
    ),
    translated: true,
  },
  {
    path: `/voucher/*`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "ActivateGiftCardPage" */ './pages/ActivateGiftCardPage/ActivateGiftCardPage'
        ),
    ),
    translated: true,
  },
  {
    path: `/purchase-complete`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "BuyGiftCardDonePage" */ './pages/BuyGiftCardPage/BuyGiftCardDonePage'
        ),
    ),
    translated: true,
  },
  {
    path: `/invite`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "ReferralPage" */ './pages/ReferralPage/ReferralPage'
        ),
    ),
    translated: true,
  },
  {
    path: ['/nike/*', '/nike-50/*'],
    component: loadable(
      () =>
        import(/* webpackChunkName: "NikePage" */ './pages/NikePage/NikePage'),
    ),
    translated: true,
  },
  {
    path: `/nikenutrition2020/*`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NikePageEu" */ './pages/NikePage/NikePageEu'
        ),
    ),
    translated: true,
  },
  {
    path: '/plan-quiz',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "PlanQuizPage" */ './pages/PlanQuizPage/PlanQuizPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/nutrition-explained',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionStart" */ './pages/NutritionExplained/NutritionStart'
        ),
    ),
  },
  {
    path: '/nutrition-explained/experts',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "AdvisoryBoard" */ './pages/NutritionExplained/AdvisoryBoard'
        ),
    ),
  },
  {
    path: ['/nutrition-explained/videos', '/nutrition-explained/video/:video'],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionVideos" */ './pages/NutritionExplained/NutritionVideos'
        ),
    ),
  },
  {
    path: [
      '/nutrition-explained/category/:category',
      '/nutrition-explained/recipes/',
    ],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionCategory" */ './pages/NutritionExplained/NutritionCategory/NutritionCategory'
        ),
    ),
  },
  {
    path: '/nutrition-explained/recipe/:slug',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionRecipe" */ './pages/NutritionExplained/NutritionRecipe'
        ),
    ),
    translated: true,
  },
  {
    path: [
      '/nutrition-explained/page/:pageNumber',
      '/nutrition-explained/archive/:pageNumber',
    ],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionArticlesAll" */ './pages/NutritionExplained/NutritionArticlesAll'
        ),
    ),
  },
  {
    path: '/nutrition-explained/search/:query',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionSearchResults" */ './pages/NutritionExplained/NutritionSearchResults'
        ),
    ),
  },
  {
    path: '/nutrition-explained/:slug',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NutritionArticle" */ './pages/NutritionExplained/NutritionArticle'
        ),
    ),
  },
  {
    path: ['/news/:slug', '/health/:slug'],
    component: loadable(() => import('./pages/DatoNativeAd/DatoNativeAd')),
    translated: true,
  },
  {
    path: ['/webinar-se/*', '/webinars-se/*', '/webinars-uk/*'],
    component: loadable(() => import('js/pages/WebcastPage/WebcastPage')),
  },
  {
    path: '/features',
    component: loadable(() => import('./pages/FeaturesPage/FeaturesPage')),
    translated: true,
  },
  {
    path: ['/gift-card', '/gift-card/:code'],
    component: loadable(
      () => import('./pages/BuyGiftCardPage/BuyGiftCardPage'),
    ),
    translated: true,
  },
  {
    path: [
      '/page/:slug',
      '/press',
      '/lifesum-games',
      '/disclaimer',
      '/ambassadorswe',
      '/germany',
    ],
    component: loadable(() => import('./pages/DatoPage/DatoPage')),
    translated: true,
  },
  {
    path: '/partner/:slug/*',
    component: loadable(() => import('./pages/PartnerPage/CmsPartnerPage')),
    translated: true,
  },
  {
    path: '/samsung-2022/*',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "Samsung2022" */ './pages/Samsung2022/Samsung2022'
        ),
    ),
  },
  {
    path: '/key-experience-2022/*',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "KeyExperience2022" */ './pages/KeyExperience2022/KeyExperience2022'
        ),
    ),
  },
  {
    path: '/sv-key-experience-2022/*',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "KeyExperience2022" */ './pages/KeyExperience2022/KeyExperience2022Sv'
        ),
    ),
  },
  {
    path: ['/gympass', '/gympass/signup'],
    component: loadable(
      () => import(/* webpackChunkName: "Gympass" */ './pages/Gympass/Gympass'),
    ),
  },
]
  .map(({ path, ...rest }) =>
    Array.isArray(path)
      ? path.map((path) => ({ path, ...rest }))
      : { path, ...rest },
  )
  .flat()

export const clientRoutes = [
  {
    path: ['/redeem/:slug', '/influencer/:slug'],
    component: loadable(() => import('./pages/Redeem/DatoRedeemPage')),
    translated: true,
  },
  {
    path: [
      '/redeem/denice30',
      '/sv/redeem/denice30',
      '/redeem/kampanjdec19',
      '/sv/redeem/kampanjdec19',
    ],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "DenicePage" */ './pages/DenicePage/DenicePage'
        ),
    ),
  },
  {
    path: ['/deep', '/deep/*'],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "FallbackPage" */ './pages/FallbackPage/FallbackPage'
        ),
    ),
  },
  {
    path: '/amazon-halo',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "AmazonPage" */ './pages/AmazonPage/AmazonPage'
        ),
    ),
  },
  {
    path: ['/work', '/insights'],
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "LifesumForWork" */ './pages/LifesumForWork/LifesumForWork'
        ),
    ),
  },
  {
    path: '/nikeplusfr/*',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "NikePlusFRPage" */ './pages/NikePage/NikePlusFRPage'
        ),
    ),
  },
  {
    path: '/oscar/*',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "OscarHealth" */ './pages/OscarHealth/OscarHealth'
        ),
    ),
  },
  {
    path: '/email/*',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "EmailLandingPage" */ './pages/EmailLandingPage'
        ),
    ),
  },
  {
    path: '/friskvard',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "FriskvardPage" */ './pages/FriskvardPage/FriskvardPage'
        ),
    ),
  },
  {
    path: '/verify/:code',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "VerifyPage" */ './pages/VerifyPage/VerifyPage'
        ),
    ),
    translated: true,
  },
  {
    path: '/quiz',
    component: loadable(
      () => import(/* webpackChunkName: "QuizAd" */ './pages/QuizAd/QuizAd'),
    ),
  },
]
  .map(({ path, ...rest }) =>
    Array.isArray(path)
      ? path.map((path) => ({ path, ...rest }))
      : { path, ...rest },
  )
  .flat()

export default routes
