/* global RELEASE_VERSION */
import { useEffect, useRef, useCallback } from 'react'
import { environment } from 'js/config'
import { useLocation } from 'react-router-dom'
import { getItem } from 'js/storeManager'

function useTracking() {
  const prevLocation = useRef<Partial<Location>>()
  const location = useLocation()

  const loadTrackingScripts = useCallback(function loadTracking() {
    if (!getItem('accepted-cookies')) return
    if (navigator.userAgent.match('DisableForTesting')) return

    setTimeout(() => {
      addTrackers()
    }, 1000)
  }, [])

  useEffect(() => {
    if (window.errorOutsideReact) {
      loadTrackingScripts()
    }
    window.addEventListener('scroll', loadTrackingScripts, {
      once: true,
    })
    document.addEventListener('loadTracking', loadTrackingScripts)

    // load tracking scripts if the user has accepted cookies after a delay
    let timeoutId
    if (getItem('accepted-cookies')) {
      timeoutId = setTimeout(loadTrackingScripts, 1500)
    }

    return () => {
      window.removeEventListener('scroll', loadTrackingScripts)
      document.removeEventListener('loadTracking', loadTrackingScripts)
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // load tracking scripts when the user navigates to a new page
    if (
      typeof prevLocation.current !== 'undefined' &&
      prevLocation.current.pathname !== location.pathname
    ) {
      loadTrackingScripts()
    }
    prevLocation.current = location
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function addTrackers() {
    const acceptedCookies = getItem('accepted-cookies')
      ? JSON.parse(getItem('accepted-cookies') as string)
      : []
    let trackers = [
      import('js/trackers/google'), // Handled with gtag('consent')
    ]

    if (acceptedCookies.includes('marketing'))
      trackers = trackers.concat([
        import('js/trackers/facebook'),
        // @ts-ignore tsc complains about side effects only dynamic imports that
        // should work according to: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
        import('js/trackers/adjust'),
        // @ts-ignore
        import('js/trackers/awin'),
        // @ts-ignore
        import('js/trackers/outbrain'),
      ])

    if (acceptedCookies.includes('analytics'))
      trackers = trackers.concat([
        // @ts-ignore tsc complains about side effects only dynamic imports that
        // should work according to: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
        import('js/trackers/hotjar'),
        new Promise<void>((resolve) => {
          if (environment === 'development') resolve()
          return import('@sentry/browser').then((Sentry) => {
            resolve()
            window.sentryLoaded = true
            window['Sentry'] = Sentry
            Sentry.init({
              beforeSend: (event, hint) => {
                if (
                  event.request &&
                  event.request.headers &&
                  event.request.headers['User-Agent'] &&
                  event.request.headers['User-Agent'].match(
                    'Mozilla/5.0 (Java) outbrain',
                  )
                ) {
                  return null
                }

                const error = hint?.originalException
                if (!error || !(error instanceof Error)) {
                  return event
                }

                if (
                  error.message.match(
                    /onAPWebViewPause|webkitExitFullScreen|navBarMenuCollapses|callbackSharePreferencesNotify|onAPWebViewResume|setAdGeneParamsForiOSApp|Can't find variable: iom|pktAnnotationHighlighter|cancelado|annullato|Abgebrochen|geannuleerd|cancelled|отменено|avbruten|avbrutt|annulleret|geannuleerd|annulé/,
                  )
                ) {
                  return null
                }

                if (error.message.match(/^Loading chunk /)) {
                  event.fingerprint = [
                    'ChunkLoadError: Loading <chunkname> failed.',
                  ]
                  // Ignore 99% of these errors since we have too many and since
                  // we aren't totally sure what causes them, we keep 1% to be
                  // able to spot changes in the frequency of these etc.
                  if (Math.floor(Math.random() * 100) !== 0) {
                    return null
                  }
                }

                if (
                  error.message.match(
                    /^SecurityError: Blocked a frame with origin "https:\/\/lifesum.com"/,
                  )
                ) {
                  // Ignore 99% of these errors since we have too many and since
                  // we aren't totally sure what causes them, we keep 1% to be
                  // able to spot changes in the frequency of these etc.
                  if (Math.floor(Math.random() * 100) !== 0) {
                    return null
                  }
                }

                return event
              },
              dsn: 'https://63c3a9e439904e03b5b944a6207f67e2@sentry.io/1440941',
              environment:
                window.location.host === 'lifesum.com'
                  ? 'production'
                  : 'staging',
              release: `lifesum-web@${RELEASE_VERSION}`,
            })

            window.sentryQueue.forEach((error) => {
              if (Array.isArray(error)) {
                if (error[4]) {
                  Sentry.captureException(error[4], {
                    extra: {
                      file: error[1],
                      line: error[2],
                      col: error[3],
                    },
                  })
                } else {
                  Sentry.captureException(error[0])
                }
              } else {
                Sentry.withScope((scope) => {
                  scope.setExtras(error.errorInfo)
                  Sentry.captureException(error.error)
                })
              }
            })
          })
        }),
      ])

    return Promise.all(trackers)
  }

  return
}

export default useTracking
