/* global AWIN */
import createAdapter from './createAdapter'
import { getItem, setItem, removeItem } from '../../storeManager'
import { environment } from 'js/config'
import { excludeVAT } from '../../utils'

export const awin = createAdapter(() => !!window.AWIN, {
  trackPurchase(transactionId, coupon, orderValue, currency) {
    if (!getItem('state')) {
      throw new Error('awinAdapter.ts error: no state saved')
    }

    const { user: userState, locale: localeState } = JSON.parse(
      getItem('state') as string,
    )
    const awc = getItem('awinAwc')
    const userId = userState.profile.id || userState.limitedProfile.id
    const country = userState.profile.country || localeState.country

    if (awc && userId) {
      if (!coupon) {
        coupon = null
      }

      var amount = parseFloat(excludeVAT(orderValue, country)).toFixed(2)

      var awPixel = new Image(0, 0)
      awPixel.src =
        'https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=113414&amount=' +
        amount +
        '&ch=aw&parts=DEFAULT:' +
        amount +
        '&ref=' +
        transactionId +
        '&vc=' +
        coupon +
        '&cr=' +
        currency +
        '&testmode=' +
        (environment === 'production' ? 0 : 1)
      document.getElementsByTagName('body')[0].appendChild(awPixel)

      AWIN.Tracking.Sale = {
        amount: '' + amount,
        channel: 'aw',
        orderRef: '' + transactionId,
        parts: 'DEFAULT:' + amount,
        currency: '' + currency,
        voucher: '' + coupon,
        test: '' + (environment === 'production' ? 0 : 1),
      }

      AWIN.Tracking.run()

      removeItem('awinAwc')
    }
  },
  saveId() {
    const awc = new URLSearchParams(document.location.search).get('awc')
    if (awc && document.location.pathname == '/redeem/awin') {
      setItem('awinAwc', awc)
    }
  },
})
