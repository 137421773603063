import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import loadable from '@loadable/component'
import * as device from './device'
import { languages as allLanguages } from 'js/locale'
import SentryBoundary from './SentryBoundary'
import RouteChangeHandler from './components/RouteChangeHandler'
import LanguageProvider from './components/LanguageProvider'
import PriceUpdater from './components/PriceUpdater'
import { AbTestProvider } from './hooks/useAbTest'
import { Title } from 'react-head'
import { ga, adrecord, awin } from 'js/analytics/adapters'
import { useDispatch } from 'react-redux'
import { detectInitialLocale } from './store/localeSlice'
import useLocale from './hooks/useLocale'
import { adjust } from './analytics/adapters/adjustAdapter'
import useTracking from 'js/hooks/useTracking'
import CookieConsent from './components/CookieConsent/CookieConsent'
import { AppDispatch } from './app'
import { ShowMenuProvider } from './hooks/useShowMenu'

const Deeplink = () => {
  const type = 'premium'
  const fallback = '/premium'

  if (device.deeplinks[type]) {
    const time = new Date().valueOf()

    setTimeout(() => {
      const delta = new Date().valueOf() - time
      if (delta <= 100 && device.appStoreURL)
        window.location.href = device.appStoreURL
    }, 25)

    window.location.href = device.deeplinks[type]
  } else if (fallback) {
    window.location.href = fallback
  }
  return <></>
}

const PageNotFound = loadable(
  () => import(/* webpackChunkName: "ErrorPage" */ './pages/ErrorPage'),
)

type Props = {
  abTests: {}
  cloudfrontViewerCountry?: string
  routes: Array<{
    path: string | string[]
    component: React.FunctionComponent<{
      getInitialProps: () => void
      initialData?: {} | []
      initialPathname?: string
    }>
    translated?: boolean
  }>
  initialData?: {} | any[]
  initialPathname?: string
  defaultMessages?: {}
  localeOverrides?: {
    language: string
    currency: string
    country: string
  }
}

const App = ({
  defaultMessages,
  initialData,
  initialPathname,
  routes,
  localeOverrides,
  abTests = {},
  cloudfrontViewerCountry,
}: Props) => {
  let { language, currency, country } = useLocale()
  const dispatch = useDispatch() as AppDispatch
  useTracking()
  const navigate = useNavigate()

  useEffect(() => {
    if (cloudfrontViewerCountry) {
      ga.set('dimension1', cloudfrontViewerCountry)
    }
  }, [cloudfrontViewerCountry])

  useEffect(() => {
    adrecord.saveId()
    awin.saveId()
    adjust.init()
    dispatch(detectInitialLocale(navigate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if ((!language || !currency || !country) && !localeOverrides) {
    return null
  }

  return (
    <SentryBoundary>
      <Title>
        Lifesum Health App - Get Healthy, Lose Weight, or Gain Muscle
      </Title>
      <LanguageProvider defaultMessages={defaultMessages}>
        <AbTestProvider abTests={abTests}>
          <RouteChangeHandler>
            <PriceUpdater>
              <ShowMenuProvider>
                <>
                  <Routes>
                    <Route path="/deeplink/premium" element={<Deeplink />} />
                    {routes.map((route, index) => {
                      const languagesToRender = route.translated
                        ? allLanguages
                        : ['en']

                      const RouteComponent = route.component

                      const paths = Array.isArray(route.path)
                        ? route.path
                        : [route.path]

                      return paths
                        .map((path) =>
                          languagesToRender.map((language) => {
                            const languageSlug =
                              language === 'en' ? '' : `/${language}`
                            const pathWithLanguageSlug = `${languageSlug}${path}`

                            return (
                              <Route
                                key={`${language}-${index}`}
                                path={pathWithLanguageSlug}
                                element={
                                  <RouteComponent
                                    getInitialProps={
                                      route.component['getInitialProps']
                                    }
                                    initialPathname={initialPathname}
                                    initialData={initialData}
                                  />
                                }
                              />
                            )
                          }),
                        )
                        .flat()
                    })}
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                  <CookieConsent />
                </>
              </ShowMenuProvider>
            </PriceUpdater>
          </RouteChangeHandler>
        </AbTestProvider>
      </LanguageProvider>
    </SentryBoundary>
  )
}

export default App
