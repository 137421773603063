const vat = {
  AE: 0.05,
  AT: 0.2,
  AU: 0.1,
  BE: 0.21,
  BG: 0.2,
  BH: 0.05,
  BY: 0.2,
  CH: 0.077,
  CL: 0.19,
  CO: 0.19,
  CY: 0.19,
  CZ: 0.21,
  DE: 0.19,
  DK: 0.25,
  EE: 0.2,
  ES: 0.21,
  EG: 0.14,
  FI: 0.24,
  FR: 0.2,
  GB: 0.2,
  GH: 0.15,
  GR: 0.24,
  HR: 0.25,
  HU: 0.27,
  IE: 0.21,
  IN: 0.18,
  IT: 0.22,
  KR: 0.1,
  LT: 0.21,
  LU: 0.16,
  LV: 0.21,
  MX: 0.16,
  MY: 0.06,
  NL: 0.21,
  NO: 0.25,
  NZ: 0.15,
  PL: 0.23,
  PT: 0.23,
  RO: 0.19,
  RS: 0.2,
  RU: 0.2,
  SA: 0.15,
  SE: 0.25,
  SG: 0.08,
  SI: 0.22,
  SK: 0.2,
  TR: 0.2,
  TW: 0.05,
  TZ: 0.2,
  UZ: 0.12,
  ZA: 0.15,
  ZW: 0.15,
}

export const excludeVAT = (value, country) => {
  if (!vat[country]) return value
  return value / (1 + vat[country])
}
